export const MOBILE_SMALL = "MOBILE_SMALL";
export const MOBILE_MEDIUM = "MOBILE_MEDIUM";
export const MOBILE_LARGE = "MOBILE_LARGE";
export const TABLET_VERTICAL = "TABLET_VERTICAL";
export const TABLET_HORIZONTAL = "TABLET_HORIZONTAL";
export const DESKTOP_SMALL = "DESKTOP_SMALL";
export const DESKTOP_MEDIUM = "DESKTOP_MEDIUM";
export const DESKTOP_LARGE = "DESKTOP_LARGE";

const deviceMinimumWidth = {
  MOBILE_SMALL: 0,
  MOBILE_MEDIUM: 375,
  MOBILE_LARGE: 425,
  TABLET_VERTICAL: 768,
  TABLET_HORIZONTAL: 800,
  DESKTOP_SMALL: 1024,
  DESKTOP_MEDIUM: 1536,
  DESKTOP_LARGE: 2520,
};

export function getBreakpointByDeviceWidth() {
  const currentDeviceWidth = window.innerWidth;
  let result = MOBILE_SMALL;

  for (const deviceType in deviceMinimumWidth) {
    if (currentDeviceWidth < deviceMinimumWidth[deviceType]) {
      return result;
    }

    result = deviceType;
  }

  return result;
}

export function getNeededBreakpoint(breakpoints) {
  const availableBreakpoints = Object.keys(deviceMinimumWidth);

  const lastIndex = availableBreakpoints.indexOf(getBreakpointByDeviceWidth());

  const properBreakpoints = availableBreakpoints
    .slice(0, lastIndex + 1)
    .reverse();

  for (const breakpoint of properBreakpoints) {
    if (breakpoints.indexOf(breakpoint) >= 0) {
      return breakpoint;
    }
  }

  return MOBILE_SMALL;
}
