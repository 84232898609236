import { createApp } from 'vue';
import App from './App.vue';
import store from "@/assets/store/index";
import "vue3-carousel/dist/carousel.css";

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faUserSecret, faUser } from '@fortawesome/free-solid-svg-icons'
import { faFacebookF, faTwitter, faLinkedin} from '@fortawesome/free-brands-svg-icons'
import i18n from "@/lang/i18n";

/* add icons to the library */
library.add(faUserSecret, faUser, faFacebookF, faTwitter, faLinkedin)
library.add(fas, far, fab)

const app = createApp(App)
    .use(i18n)
    .use(store)
    .component('font-awesome-icon', FontAwesomeIcon)
app.config.globalProperties.$frontendUri = process.env.VUE_APP_FRONTEND_URI;
app.mount('#app');
