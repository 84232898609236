export default {
    state: () => ({
        isMobileMenuBarVisible: false,
    }),
    mutations: {
        setMobileMenuBarVisible(state) {
            state.isMobileMenuBarVisible = true;
        },
        setMobileMenuBarHidden(state) {
            state.isMobileMenuBarVisible = false;
        },
        setMobileUserBarVisible(state) {
            state.isMobileUserBarVisible = true;
        },
        setMobileUserBarHidden(state) {
            state.isMobileUserBarVisible = false;
        },
        setChatPanelVisible(state) {
            state.isChatPanelVisible = true;
        },
        setChatPanelHidden(state) {
            state.isChatPanelVisible = false;
        },
    },
    actions: {
        openMobileMenuBar({commit, dispatch}) {
            dispatch('closeAllBars');
            commit('setMobileMenuBarVisible');
            if (!document.body.classList.contains('is-sidebar-opened')) {
                document.body.classList.add('is-sidebar-opened');
            }
        },
        closeMobileMenuBar({commit}) {
            commit('setMobileMenuBarHidden');
            if (document.body.classList.contains('is-sidebar-opened')) {
                document.body.classList.remove('is-sidebar-opened');
            }
        },
        openMobileUserBar({commit, dispatch}) {
            dispatch('closeAllBars');
            commit('setMobileUserBarVisible');
            if (!document.body.classList.contains('is-sidebar-opened')) {
                document.body.classList.add('is-sidebar-opened');
            }
        },
        closeMobileUserBar({commit}) {
            commit('setMobileUserBarHidden');
            if (document.body.classList.contains('is-sidebar-opened')) {
                document.body.classList.remove('is-sidebar-opened');
            }
        },
        openChat({commit, dispatch}) {
            dispatch('closeAllBars');
            commit('setChatPanelVisible');
        },
        closeChat({commit}) {
            commit('setChatPanelHidden');
        },
        toggleChat({state, dispatch}) {
            if (state.isChatPanelVisible) {
                dispatch('closeChat');
            } else {
                dispatch('openChat');
            }
        },
        closeAllBars({dispatch}) {
            dispatch('closeMobileMenuBar');
            dispatch('closeMobileUserBar');
            dispatch('closeChat');
        },
    },
    namespaced: true,
};
