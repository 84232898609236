<template>
  <div class="facts-step-block">
    <div class="facts-step-block__content">
      <h2 class="facts-step-block__content-title">
        {{$t('factsStepBlock.title')}}
      </h2>
      <div class="facts-step-block__content-description">
        {{$t('factsStepBlock.description')}}
      </div>
    </div>

    <div class="facts-step-block__items-wrapper">
      <div class="facts-step-block__items">
        <div class="facts-step-block__items-circle">
          <div class="facts-step-block__items-circle-icon">
            <img src="@/assets/images/step-block-facts/icons/1.png" alt="icon1"/>
          </div>
        </div>
        <div class="facts-step-block__items-content">
          <div class="facts-step-block__items-title">
            {{$t('factsStepBlock.factsStepBlockOneTitle')}}
          </div>
          <div class="facts-step-block__items-description">
            {{$t('factsStepBlock.factsStepBlockOneDescription')}}
          </div>
        </div>
      </div>

      <div class="facts-step-block__items">
        <div class="facts-step-block__items-circle">
          <div class="facts-step-block__items-circle-icon">
            <img src="@/assets/images/step-block-facts/icons/2.png" alt="icon2"/>
          </div>
        </div>
        <div class="facts-step-block__items-content">
          <div class="facts-step-block__items-title">
            {{$t('factsStepBlock.factsStepBlockTwoTitle')}}
          </div>
          <div class="facts-step-block__items-description">
            {{$t('factsStepBlock.factsStepBlockTwoDescription')}}
          </div>
        </div>
      </div>

      <div class="facts-step-block__items">
        <div class="facts-step-block__items-circle">
          <div class="facts-step-block__items-circle-icon">
            <img src="@/assets/images/step-block-facts/icons/3.png" alt="icon3"/>
          </div>
        </div>
        <div class="facts-step-block__items-content">
          <div class="facts-step-block__items-title">
            {{$t('factsStepBlock.factsStepBlockThreeTitle')}}
          </div>
          <div class="facts-step-block__items-description">
            {{$t('factsStepBlock.factsStepBlockThreeDescription')}}
          </div>
        </div>
      </div>

      <div class="facts-step-block__items">
        <div class="facts-step-block__items-circle">
          <div class="facts-step-block__items-circle-icon">
            <img src="@/assets/images/step-block-facts/icons/4.png" alt="icon4"/>
          </div>
        </div>
        <div class="facts-step-block__items-content">
          <div class="facts-step-block__items-title">
            {{$t('factsStepBlock.factsStepBlockFourTitle')}}
          </div>
          <div class="facts-step-block__items-description">
            {{$t('factsStepBlock.factsStepBlockFourDescription')}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FactsStepBlock"
}
</script>

<style scoped>

</style>