<template>
  <div class="mobile-main-menu">
    <div class="mobile-main-menu__logo" />

    <div class="divider divider_is-not-full-screen" />

    <div class="mobile-main-menu__list">

      <a
          target="_blank"
          :href="$frontendUri+'/for-buyers'"
          class="mobile-main-menu__list-item"
          active-class="mobile-main-menu__item_active"
      >
        {{ $t('headerMobileMenu.for_buyers') }}
      </a>


      <a
          target="_blank"
          :href="$frontendUri+'/for-sellers'"
          class="mobile-main-menu__list-item"
          active-class="mobile-main-menu__item_active"
      >
        {{ $t('headerMobileMenu.for_sellers') }}
      </a>
    </div>

    <div class="divider divider_is-not-full-screen" />

    <div class="mobile-main-menu__footer">
      <div class="mobile-main-menu__button-register">
        <div class="button-accent">
          <a href="egolist.ua/#signup" target="_blank">
            {{ $t('headerMobileMenu.register') }}
          </a>
        </div>
      </div>
      <div class="mobile-main-menu__socials">
        <a class="mobile-main-menu__socials-item" href="https://www.facebook.com/egolistua" target="_blank">
          <font-awesome-icon icon="fa-brands fa-facebook-square" />
        </a>
        <a class="mobile-main-menu__socials-item" href="https://twitter.com/egolist_ua" target="_blank">
          <font-awesome-icon icon="fa-brands fa-twitter-square" />
        </a>
        <a class="mobile-main-menu__socials-item" href="https://www.linkedin.com/company/egolistua" target="_blank">
          <font-awesome-icon icon="fa-brands fa-linkedin" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderMobileMenu',
  computed:{

  },
  methods: {

  },
};
</script>

<style scoped>

</style>