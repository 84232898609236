<template>
  <div class="footer">
    <h2 class="footer__title">
      {{$t('footer.title')}}
    </h2>
    <div class="footer__description">
      {{$t('footer.description')}}
    </div>
    <div class="footer__button">
      <a :href="$frontendUri" target="_blank">
        <div class="button button-accent">
            {{$t('footer.try')}}
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer-block"
}
</script>

<style scoped>

</style>