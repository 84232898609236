<template>
  <div class="desktopHeader">
    <div class="desktopHeader__content">
      <div class="desktopHeader__logo">
        <img alt="logo-header" class="desktopHeader__logo-img" src="@/assets/images/header/logo.svg"/>
      </div>
      <div class="desktopHeader__navigation">
        <a :href="$frontendUri+'/for-buyers'" target="_blank">
          <div class="desktopHeader__navigation-item">
            {{ $t('headerMobileMenu.for_buyers') }}
          </div>
        </a>
        <a :href="$frontendUri+'/for-sellers'" target="_blank">
          <div class="desktopHeader__navigation-item">
            {{ $t('headerMobileMenu.for_sellers') }}
          </div>
        </a>
      </div>
      <a :href="$frontendUri+'/#signup'" target="_blank">
        <div class="button button-accent">
          Зареєструватись
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderDesktop"
}
</script>

<style scoped>

</style>