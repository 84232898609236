<template>
  <div class="how-does-it-work-step-block" id="how-does-it-work-step-block">
    <div class="how-does-it-work-step-block__content">
      <h2 class="how-does-it-work-step-block__content-title">
        {{$t('howDoesItWorkStepBlock.title')}}
      </h2>
      <div class="how-does-it-work-step-block__content-description">
        {{$t('howDoesItWorkStepBlock.description')}}
      </div>
    </div>

    <div class="how-does-it-work-step-block__content--desktop">
      <div class="how-does-it-work-step-block__content-title">
        {{$t('howDoesItWorkStepBlock.title')}}
      </div>
      <div class="how-does-it-work-step-block__content-description--desktop">
        {{$t('howDoesItWorkStepBlock.description')}}
      </div>

      <div class="how-does-it-work-step-block__instructions--desktop">
        <div class="how-does-it-work-step-block__instructions-item">
          <div class="how-does-it-work-step-block__instructions-circle">
            <div class="how-does-it-work-step-block__instructions-circle-number">
              1
            </div>
          </div>
          <div class="how-does-it-work-step-block__instructions-content">
            <div class="how-does-it-work-step-block__instructions-content-title">
              {{$t('howDoesItWorkStepBlock.howDoesItWorkStepBlockOneTitle')}}
            </div>
            <div class="how-does-it-work-step-block__instructions-content-description">
              {{$t('howDoesItWorkStepBlock.howDoesItWorkStepBlockOneDescription')}}
            </div>
          </div>
        </div>


        <div class="how-does-it-work-step-block__instructions-item">
          <div class="how-does-it-work-step-block__instructions-circle">
            <div class="how-does-it-work-step-block__instructions-circle-number">
              2
            </div>
          </div>
          <div class="how-does-it-work-step-block__instructions-content">
            <div class="how-does-it-work-step-block__instructions-content-title">
              {{$t('howDoesItWorkStepBlock.howDoesItWorkStepBlockTwoTitle')}}
            </div>
            <div class="how-does-it-work-step-block__instructions-content-description">
              {{$t('howDoesItWorkStepBlock.howDoesItWorkStepBlockTwoDescription')}}
            </div>
          </div>
        </div>


        <div class="how-does-it-work-step-block__instructions-item">
          <div class="how-does-it-work-step-block__instructions-circle">
            <div class="how-does-it-work-step-block__instructions-circle-number">
              3
            </div>
          </div>
          <div class="how-does-it-work-step-block__instructions-content">
            <div class="how-does-it-work-step-block__instructions-content-title">
              {{$t('howDoesItWorkStepBlock.howDoesItWorkStepBlockThreeTitle')}}
            </div>
            <div class="how-does-it-work-step-block__instructions-content-description">
              {{$t('howDoesItWorkStepBlock.howDoesItWorkStepBlockThreeDescription')}}
            </div>
          </div>
        </div>

      </div>

      <a :href="$frontendUri" target="_blank">
        <div class="button button-accent how-does-it-work-step-block__button--desktop">
            Спробувати
        </div>
      </a>
    </div>

    <div class="how-does-it-work-step-block__instructions">
      <div class="how-does-it-work-step-block__instructions-item">
        <div class="how-does-it-work-step-block__instructions-circle">
          <div class="how-does-it-work-step-block__instructions-circle-number">
            1
          </div>
        </div>
        <div class="how-does-it-work-step-block__instructions-content">
          <div class="how-does-it-work-step-block__instructions-content-title">
            {{$t('howDoesItWorkStepBlock.howDoesItWorkStepBlockOneTitle')}}
          </div>
          <div class="how-does-it-work-step-block__instructions-content-description">
            {{$t('howDoesItWorkStepBlock.howDoesItWorkStepBlockOneDescription')}}
          </div>
        </div>
      </div>


      <div class="how-does-it-work-step-block__instructions-item">
        <div class="how-does-it-work-step-block__instructions-circle">
          <div class="how-does-it-work-step-block__instructions-circle-number">
            2
          </div>
        </div>
        <div class="how-does-it-work-step-block__instructions-content">
          <div class="how-does-it-work-step-block__instructions-content-title">
            {{$t('howDoesItWorkStepBlock.howDoesItWorkStepBlockTwoTitle')}}
          </div>
          <div class="how-does-it-work-step-block__instructions-content-description">
            {{$t('howDoesItWorkStepBlock.howDoesItWorkStepBlockTwoDescription')}}
          </div>
        </div>
      </div>


      <div class="how-does-it-work-step-block__instructions-item">
        <div class="how-does-it-work-step-block__instructions-circle">
          <div class="how-does-it-work-step-block__instructions-circle-number">
            3
          </div>
        </div>
        <div class="how-does-it-work-step-block__instructions-content">
          <div class="how-does-it-work-step-block__instructions-content-title">
            {{$t('howDoesItWorkStepBlock.howDoesItWorkStepBlockThreeTitle')}}
          </div>
          <div class="how-does-it-work-step-block__instructions-content-description">
            {{$t('howDoesItWorkStepBlock.howDoesItWorkStepBlockThreeDescription')}}
          </div>
        </div>
      </div>
    </div>

    <iframe
        width="630"
        height="415"
        src="https://www.youtube.com/embed/T1yFGcJK8s8"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        modestbranding=1
        allowfullscreen>
    </iframe>
  </div>
</template>

<script>
export default {
  name: "HowDoesItWorkStepBlock",
  components: {},
}
</script>

<style scoped>

</style>