<template>
  <div class="panel panel__content panel_highlighted user-stories-block">
    <div class="comments-step-block__content">
      <h2 class="comments-step-block__content-title">
        Відгуки користувачів Egolist
      </h2>

      <div class="comments-step-block__content-description">
        Ми тільки нещодавно запустились, але вже є багато позитиву
      </div>
    </div>
    <carousel
        v-if="!isCarouselRestarting"
        :settings="settings"
        class="user-stories-block__carousel"
    >
      <slide v-for="(step, key) in steps" :key="key">
        <div class="user-stories-block__slide">
          <iframe
              :src="step.src"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
          />
        </div>
      </slide>
      <template #addons>
        <pagination class="user-stories-block__pagination" />
        <navigation class="user-stories-block__navigation" />
      </template>
    </carousel>
  </div>
</template>

<script>
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import { debounce } from "debounce";
import { nextTick } from "vue";
import { getNeededBreakpoint } from "@/utils/responsive";

export default {
  name: "UserStoriesBlock",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  created() {
    this.settings = this.getCarouselSetting();
    window.addEventListener("resize", this.handleWindowResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
  },
  data() {
    return {
      isCarouselRestarting: false,
      settings: {},
      settingsForCarousel: {
        MOBILE_SMALL: {
          itemsToShow: 1,
          autoplay: 0,
          snapAlign: "right",
          wrapAround: true,
          touchDrag: true,
        },
        TABLET_VERTICAL: {
          itemsToShow: 1,
          autoplay: 0,
          wrapAround: true,
          touchDrag: true,
          snapAlign: "left",
        },
        DESKTOP_SMALL: {
          itemsToShow: 3,
          autoplay: 0,
          wrapAround: true,
          touchDrag: false,
          mouseDrag: false,
          snapAlign: "left",
        },
      },
      steps: [
        {
          src: "https://www.youtube.com/embed/4lkUGceX-vc?controls=2",
        },
        {
          src: "https://www.youtube.com/embed/Ab3Qra_Ol74?controls=2",
        },
        {
          src: "https://www.youtube.com/embed/FIBZY-3i3gw?controls=2",
        },
      ],
    };
  },
  methods: {
    handleWindowResize: debounce(async function () {
      this.isCarouselRestarting = true;
      await nextTick();
      this.settings = this.getCarouselSetting();
      await nextTick();
      this.isCarouselRestarting = false;
    }, 500),
    getCarouselSetting: function () {
      return this.settingsForCarousel[
          getNeededBreakpoint(Object.keys(this.settingsForCarousel))
          ];
    },
  },
};
</script>
