<template>
  <div class="header-step-block">
    <video autoplay preload muted loop class="header-step-block__image">
      <source src="https://s3.eu-central-1.amazonaws.com/storage.prod.egolist.ua/landing_animation.mp4" />
    </video>

    <div class="header-step-block__content">
      <div class="header-step-block__content-title">
        <h1>
          Знаходь що треба<br>
          <span class="header-step-block__content-success">на вигідних умовах</span><br>
          через тендер!
        </h1>
      </div>

      <div class="header-step-block__content-description">
        {{$t('headerStepBlock.description')}}
      </div>
    </div>

    <a :href="$frontendUri" target="_blank">
      <div class="button button-accent">
          {{$t('headerStepBlock.goAndTry')}}
      </div>
    </a>

    <div class="header-step-block__content-button-description">
      <div class="header-step-block__circle-info">i</div>
      <div>{{$t('headerStepBlock.createButtonInfo')}}<div>
      </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderStepBlockMobile"
}
</script>

<style scoped>

</style>