<template>
  <HeaderMobile v-if="isMobile"/>
  <HeaderDesktop v-if="!isMobile"/>
</template>

<script>
import HeaderMobile from "@/components/header/HeaderMobile";
import HeaderDesktop from "@/components/header/HeaderDesktop";

export default {
  name: 'Header-landing',
  components: {HeaderDesktop, HeaderMobile},
  data() {
    return {
      isMobile: null,
    }
  },
  methods: {
    updateWidth() {
      if(window.innerWidth < 1024) {
        this.isMobile = true;
      } else{
        this.isMobile = false;
      }
    },
  },
  created() {
    window.addEventListener('resize', this.updateWidth);
    this.updateWidth();
  },
}
</script>

<style scoped>

</style>