export default {
    open_tenders: 'Відкриті тендери',
    for_buyers: 'Покупцям',
    for_sellers: 'Продавцям',
    connect_bot: 'Підключити бота',
    about_egolist: 'Про Egolist',
    my_tenders: 'Мої тендери',
    my_offers: 'Мої пропозиції',
    home: 'Головна',
    register: 'Зареєструватись'
};
