import Vuex from 'vuex';
import sidebars from "@/assets/store/sidebars/sidebars";

export default new Vuex.Store({
    state: {},
    modules: {
        sidebars
    },
    mutations: {},
});
