export default {
    title: 'Ваш надійний партнер для пошуку найкращого рішення',
    description: 'Більше не потрібно витрачати час на пошук та моніторинг в інтернеті',
    factsStepBlockOneTitle: 'Cистема рейтингу',
    factsStepBlockOneDescription: 'Кожна пропозиція має рейтинг, який розраховується з опису, картинок та ціни. Цереглядайте одразу краще!',
    factsStepBlockTwoTitle: 'Консьєрж сервіс',
    factsStepBlockTwoDescription: 'Egolist додатково підбирає варіанти у різних цінових категоріях, перевіряє наявність та відповідність ціни ринку.',
    factsStepBlockThreeTitle: 'Безпечно',
    factsStepBlockThreeDescription: 'Профілі та публікації проходять перевірку модераторами, конфіденційні чати та захищеність власних даних на першому місці.',
    factsStepBlockFourTitle: 'Справедливі ціни',
    factsStepBlockFourDescription: 'Формат тендеру та відкритої конкуренції стимулює продавця зробити вам найбільш вигідну пропозицію',
}