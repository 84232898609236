<template>
  <div class="wrapper">
    <Header/>
    <div class="layout">
      <HeaderStepBlock/>
      <HowDoesItWorkStepBlock/>
      <CategoryStepBlock/>
      <FactsStepBlock/>
<!--      <CommentsStepBlock :steps="featuresBannerData"/>-->
      <UserStoriesBlock/>

      <div class="sidebar" :class="{'sidebar_opened': isMobileMenuBarVisible}">
        <div class="sidebar__close-button" @click="closeMobileMenuBar">
          <font-awesome-icon icon="fa-solid fa-xmark" class="light" />
        </div>

        <HeaderMobileMenu/>

      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import './assets/styles.sass';
import Header from "@/components/header/Header";
import HeaderStepBlock from "@/components/steps/header-step-block/HeaderStepBlock";
import HowDoesItWorkStepBlock from "@/components/steps/how-does-it-work-step-block/HowDoesItWorkStepBlock";
import CategoryStepBlock from "@/components/steps/category-step-block/CategoryStepBlock";
import FactsStepBlock from "@/components/steps/facts-step-block/FactsStepBlock";
// import CommentsStepBlock from "@/components/steps/comments-step-block/CommentsStepBlock";
import Footer from "@/components/footer/Footer";
import HeaderMobileMenu from "@/components/header/HeaderMobileMenu";
import UserStoriesBlock from "@/components/steps/user-stories-block/UserStoriesBlock";
import {mapActions, mapState} from 'vuex';
// import CarouselItem from "@/models/users/CarouselItem";

// import ava1 from "../src/assets/images/step-block-slider/ava1.jpg";
// import ava2 from "../src/assets/images/step-block-slider/ava2.jpg";
// import ava3 from "../src/assets/images/step-block-slider/ava3.jpg";

export default {
  name: 'Egolist-landing',
  // data() {
  //   return {
  //     featuresBannerData: [
  //       new CarouselItem(
  //           this.$t("sliderStepBlock.sliderBlockOneTitle"),
  //           this.$t("sliderStepBlock.sliderBlockOneHref"),
  //           this.$t("sliderStepBlock.sliderBlockOneRating"),
  //           this.$t("sliderStepBlock.sliderBlockOneDescription"),
  //           this.$t("sliderStepBlock.sliderBlockOneFooter"),
  //           this.$t("sliderStepBlock.sliderBlockOneDate"),
  //           ava1,
  //       ),
  //       new CarouselItem(
  //           this.$t("sliderStepBlock.sliderBlockTwoTitle"),
  //           this.$t("sliderStepBlock.sliderBlockTwoHref"),
  //           this.$t("sliderStepBlock.sliderBlockTwoRating"),
  //           this.$t("sliderStepBlock.sliderBlockTwoDescription"),
  //           this.$t("sliderStepBlock.sliderBlockTwoFooter"),
  //           this.$t("sliderStepBlock.sliderBlockTwoDate"),
  //           ava2,
  //       ),
  //       new CarouselItem(
  //           this.$t("sliderStepBlock.sliderBlockThreeTitle"),
  //           this.$t("sliderStepBlock.sliderBlockThreeHref"),
  //           this.$t("sliderStepBlock.sliderBlockThreeRating"),
  //           this.$t("sliderStepBlock.sliderBlockThreeDescription"),
  //           this.$t("sliderStepBlock.sliderBlockThreeFooter"),
  //           this.$t("sliderStepBlock.sliderBlockThreeDate"),
  //           ava3,
  //       ),
  //     ],
  //   }
  // },
  components: {
    UserStoriesBlock,
    Footer,
    FactsStepBlock,
    CategoryStepBlock,
    HowDoesItWorkStepBlock,
    HeaderStepBlock,
    Header,
    HeaderMobileMenu,
    // CommentsStepBlock,
  },
  computed: {
    ...mapState('sidebars', [
        'isMobileMenuBarVisible',
    ])
  },
  methods: {
    ...mapActions('sidebars', [
      'closeMobileMenuBar',
    ]),
  }
}
</script>

<style lang="sass">
</style>
