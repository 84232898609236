import { createI18n } from 'vue-i18n'
import ua from "@/lang/ua/ua";


export default createI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || 'ua',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'ua',
    messages: {
        ua: ua
    }
})