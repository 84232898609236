<template>
  <div class="header-step-block-desktop">
    <div class="header-step-block-desktop__main">
      <div class="header-step-block-desktop__content-wrapper">
          <div class="header-step-block-desktop__content-title">
            <h1>
              Знаходь що треба<br>
              <span class="header-step-block__content-success">на вигідних умовах</span><br>
              через тендер!
            </h1>
          </div>

          <div class="header-step-block-desktop__content-description">
            <div>
              {{$t('headerStepBlock.description_One')}}
            </div>
            <div>
              {{$t('headerStepBlock.description_Two')}}
            </div>
          </div>

          <div class="header-step-block-desktop__buttons-wrapper">
            <div class="header-step-block-desktop__buttons">
              <a :href="$frontendUri" target="_blank">
                <div class="button button-accent">
                  {{$t('headerStepBlock.goAndTry')}}
                </div>
              </a>
              <a href="#how-does-it-work-step-block">
                <div class="button button-frame">
                    Як це працює
                  <img alt="play-button" src="@/assets/images/step-header-block/play.svg"/>
                </div>
              </a>
            </div>

            <div class="header-step-block-desktop__content-button-description">
              <div class="header-step-block__circle-info">i</div>
              <div>{{$t('headerStepBlock.createButtonInfo')}}<div>
              </div>

              </div>
            </div>
          </div>
      </div>

      <div class="header-step-block-desktop__video-wrapper">
        <video autoplay preload loop class="header-step-block-desktop__video" muted>
          <source
              :src="srcVideo"
          />
        </video>
      </div>
    </div>

    <div class="header-step-block-info">
      <div>
        <div class="header-step-block-info__title">
          >1000
        </div>
        <div class="header-step-block-info__description">
          Користувачів сайту
        </div>
      </div>
      <div>
        <div class="header-step-block-info__title">
          478
        </div>
        <div class="header-step-block-info__description">
          Відкритих тендерів прямо зараз
        </div>
      </div>
      <div>
        <div class="header-step-block-info__title">
          12
        </div>
        <div class="header-step-block-info__description">
          Середня кількість пропозицій на тендер
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderStepBlockDesktop",
  created() {
    window.addEventListener('resize', this.updateWidth);
  },
  data: () => ({
    width: 0,
    srcVideo: 'https://s3.eu-central-1.amazonaws.com/storage.prod.egolist.ua/landing/419x366.mp4',
  }),
  methods: {
    updateWidth() {
      this.width = window.innerWidth;

      if(this.width < 1024) {
        this.srcVideo = 'https://s3.eu-central-1.amazonaws.com/storage.prod.egolist.ua/landing_animation_desktop.mp4';
      }

      if(this.width > 1024 && this.width < 1440) {
        this.srcVideo = 'https://s3.eu-central-1.amazonaws.com/storage.prod.egolist.ua/landing/419x366.mp4';
      }

      if(this.width > 1440) {
        this.srcVideo = 'https://s3.eu-central-1.amazonaws.com/storage.prod.egolist.ua/landing/521x455.mp4';
      }
    },
  },
}
</script>

<style scoped>

</style>