import categoryStepBlock from "@/lang/ua/categoryStepBlock";
import howDoesItWorkStepBlock from "@/lang/ua/howDoesItWorkStepBlock";
import factsStepBlock from "@/lang/ua/factsStepBlock";
import headerStepBlock from "@/lang/ua/headerStepBlock";
import headerMobileMenu from "@/lang/ua/headerMobileMenu";
import footer from "@/lang/ua/footer";
import sliderStepBlock from "@/lang/ua/sliderStepBlock";
import header from "@/components/header/Header";

export default {
    categoryStepBlock,
    howDoesItWorkStepBlock,
    factsStepBlock,
    headerStepBlock,
    headerMobileMenu,
    footer,
    sliderStepBlock,
    header,
}