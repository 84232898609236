export default {
    sliderBlockOneTitle: "Евгений Голота",
    sliderBlockTwoTitle: "Алекса Колотвина",
    sliderBlockThreeTitle: "Julia Grishina",

    sliderBlockOneHref: "facebook.com/profile.php?id=100041116548300",
    sliderBlockTwoHref: "facebook.com/alexa.pogodina",
    sliderBlockThreeHref: "facebook.com/yulia.grishina.169",

    sliderBlockOneRating: "",
    sliderBlockTwoRating: "",
    sliderBlockThreeRating: "",

    sliderBlockOneDescription: "Розмістив тендер на закупівлю техніки для офісу. Вдалось знайти 65% від всього, деякі варіанти були майже нові за супер ціної і ще й неподалік",
    sliderBlockTwoDescription: "Придбала Iphone 11 PRO в гарному стані та ще й в рідному місті за супер ціною. Дякую продавцю та сервісу та оперативний пошук!",
    sliderBlockThreeDescription: "Знайшла гарного майста для ремонту побутової техніки з виїздом додому по адекватній ціні. Досить непогано ))",

    sliderBlockOneFooter: "Тендер на закупівлю техніки для офісу",
    sliderBlockTwoFooter: "Придбала Iphone 11 PRO",
    sliderBlockThreeFooter: "Знайшла майстра для техніки",

    sliderBlockOneDate: "03.06.2022",
    sliderBlockTwoDate: "15.05.2022",
    sliderBlockThreeDate: "23.05.2022",
}