<template>
  <div class="category-step-block">
    <div class="category-step-block__content">
      <h2 class="category-step-block__content-title">
        {{$t('categoryStepBlock.title')}}
      </h2>
      <div class="category-step-block__content-description">
        {{$t('categoryStepBlock.description')}}
      </div>
    </div>

    <div class="row full-width">
      <a :href="$frontendUri + '/xobbi-otdyx-i-sport'" target="_blank" class="col-sm-6 col-lg-2 category-step-block__categories-item">
          <div class="category-step-block__categories-item__image">
            <img alt="ball-category" src="@/assets/images/category-step-block/ball.png">
          </div>
          <div class="category-step-block__categories-item__title">
            {{$t('categoryStepBlock.stepCategoryHobby')}}
          </div>
        </a>
      <a :href="$frontendUri + '/dom-i-sad'" target="_blank" class="col-sm-6 col-lg-2 category-step-block__categories-item">
          <div class="category-step-block__categories-item__image blue-background">
            <img alt="sofa-category" src="@/assets/images/category-step-block/sofa.png">
          </div>
          <div class="category-step-block__categories-item__title">
            {{$t('categoryStepBlock.stepCategoryHouse')}}
          </div>
        </a>
      <a :href="$frontendUri + '/elektronika'" target="_blank" class="col-sm-6 col-lg-2 category-step-block__categories-item">
        <div class="category-step-block__categories-item__image lime-background">
          <img alt="desktop-category" src="@/assets/images/category-step-block/desktop.png">
        </div>
        <div class="category-step-block__categories-item__title">
          {{$t('categoryStepBlock.stepCategoryElectronica')}}
        </div>
      </a>
      <a :href="$frontendUri + '/biznes-i-uslugi'" target="_blank" class="col-sm-6 col-lg-2 category-step-block__categories-item">
        <div class="category-step-block__categories-item__image purple-background">
          <img alt="babochka-category" src="@/assets/images/category-step-block/babochka.png">
        </div>
        <div class="category-step-block__categories-item__title">
          {{$t('categoryStepBlock.stepCategoryBiznes')}}
        </div>
      </a>
      <a :href="$frontendUri + '/detskii-mir'" target="_blank" class="col-sm-6 col-lg-2 category-step-block__categories-item">
        <div class="category-step-block__categories-item__image yellow-background">
          <img alt="colaska-category" src="@/assets/images/category-step-block/colaska.png">
        </div>
        <div class="category-step-block__categories-item__title">
          {{$t('categoryStepBlock.stepCategoryChildren')}}
        </div>
      </a>
      <a :href="$frontendUri + '/zivotnye'" target="_blank" class="col-sm-6 col-lg-2 category-step-block__categories-item">
        <div class="category-step-block__categories-item__image">
          <img alt="cat-category" src="@/assets/images/category-step-block/cat.png"/>
        </div>
        <div class="category-step-block__categories-item__title">
          {{$t('categoryStepBlock.stepCategoryAnimals')}}
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "CategoryStepBlock"
}
</script>

<style scoped>

</style>