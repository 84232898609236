<template>
  <div class="header">
    <div>
      <div class="hamburger" @click="openMobileMenuBar">
        <div class="hamburger__line"></div>
        <div class="hamburger__line"></div>
        <div class="hamburger__line"></div>
      </div>
    </div>
    <div>
      <img alt="logo-header--mobile" src="@/assets/images/header/logo.svg"/>
    </div>
    <a :href="$frontendUri+'/#signup'" target="_blank">
      <div class="user-icon">
        <font-awesome-icon icon="fa-solid fa-user" />
      </div>
    </a>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "HeaderMobile",
  methods: {
    ...mapActions('sidebars', [
      'openMobileMenuBar',
    ]),
  }
}
</script>

<style scoped>

</style>